import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ series, categories }) => {
  const chartOptions = {
    chart: {
      id: 'dynamic-line-chart',
    },
    xaxis: {
      categories: categories, // Pass the categories (x-axis) dynamically
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: 'Static & Dynamic DP Pressure Per Strokes',
      align: 'left',
    },
  };

  let finalSeries = [
    {
      name: 'Static & Dynamic DP Pressure (psi)',
      data: series, // Pass the series (y-axis) dynamically
    },
  ];

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart options={chartOptions} series={finalSeries} type="line" height="350" />
        </div>
      </div>
    </div>
  );
};

export default LineChart;