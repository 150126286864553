import DrillImage from "./drillImage.png"
import { useState } from "react";
import LineChart from "./LineChart"
import DynamicInput from "./DynamicInput";

function App() {

  const variables = {
    mudWeight: {
      name: "mudWeight",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    casingSize: {
      name: "casingSize",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    casingMDepth: {
      name: "casingMDepth",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    casingTVDepth: {
      name: "casingTVDepth",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    holeSize: {
      name: "holeSize",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    holeMDepth: {
      name: "holeMDepth",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    holeTVDepth: {
      name: "holeTVDepth",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    surfaceLeakOffPressure: {
      name: "surfaceLeakOffPressure",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    mudWeightAtTest: {
      name: "mudWeightAtTest",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    maximumAllowableMudWeight: {
      name: "maximumAllowableMudWeight",
      dependencies: ["mudWeightAtTest", "surfaceLeakOffPressure", "casingTVDepth"],
      status: "unknown",
      formula: (data) => data.mudWeightAtTest + (data.surfaceLeakOffPressure / (data.casingTVDepth * 0.052)), // Here's the formula function
      updateMethod: (value) => onMaximumAllowableMudWeightChange(value),
      value: null,
    },
    initialMAASP: {
      name: "initialMAASP",
      dependencies: ["maximumAllowableMudWeight", "mudWeight", "casingTVDepth"],
      status: "unknown",
      formula: (data) => (data.maximumAllowableMudWeight - data.mudWeight) * data.casingTVDepth * 0.052,
      updateMethod: (value) => onInitialMAASPChange(value),
      value: null,
    },
    pump1Displ: {
      name: "pump1Displ",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    pump2Displ: {
      name: "pump2Displ",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    slowPump1Rate: {
      name: "slowPump1Rate",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    slowPump2Rate: {
      name: "slowPump2Rate",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dynamicPressureLossPump1: {
      name: "dynamicPressureLossPump1",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dynamicPressureLossPump2: {
      name: "dynamicPressureLossPump2",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    // todo find dependencies
    dpLength: {
      name: "dpLength",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dpCapacity: {
      name: "dpCapacity",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dpVolume: {
      name: "dpVolume",
      dependencies: ['dpLength', 'dpCapacity'],
      status: "unknown",
      formula: (data) => data.dpLength * data.dpCapacity,
      updateMethod: (value) => onDpVolumeChange(value),
      value: null,
    },
    hwdpLength: {
      name: "hwdpLength",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    hwdpCapacity: {
      name: "hwdpCapacity",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    hwdpVolume: {
      name: "hwdpVolume",
      dependencies: ['hwdpLength', 'hwdpCapacity'],
      status: "unknown",
      formula: (data) => data.hwdpLength * data.hwdpCapacity,
      updateMethod: (value) => onHwdpVolumeChange(value),
      value: null,
    },
    dcLength: {
      name: "dcLength",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dcCapacity: {
      name: "dcCapacity",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dcVolume: {
      name: "dcVolume",
      dependencies: ['dcLength', 'dcCapacity'],
      status: "unknown",
      formula: (data) => data.dcLength * data.dcCapacity,
      updateMethod: (value) => onDcVolumeChange(value),
      value: null,
    },
    drillStringVolume: {
      name: "drillStringVolume",
      dependencies: ['dpVolume', 'hwdpVolume', 'dcVolume'],
      status: "unknown",
      formula: (data) => data.dpVolume + data.hwdpVolume + data.dcVolume,
      updateMethod: (value) => onDrillStringVolumeChange(value),
      value: null,
    },
    drillStringVolumePumpStroke: {
      name: "drillStringVolumePumpStroke",
      dependencies: ["drillStringVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.drillStringVolume / data.pump1Displ,
      updateMethod: (value) => onDrillStringVolumePumpStrokeChange(value),
      value: null,
    },
    drillStringVolumeTime: {
      name: "drillStringVolumeTime",
      dependencies: ["drillStringVolumePumpStroke", "slowPump1Rate"],
      status: "unknown",
      formula: (data) => data.drillStringVolumePumpStroke / data.slowPump1Rate,
      updateMethod: (value) => onDrillStringVolumeTimeChange(value),
      value: null,
    },
    dcOpenHoleLength: {
      name: "dcOpenHoleLength",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dcOpenHoleCapacity: {
      name: "dcOpenHoleCapacity",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dcOpenHoleVolume: {
      name: "dcOpenHoleVolume",
      dependencies: ['dcOpenHoleLength', 'dcOpenHoleCapacity'],
      status: "unknown",
      formula: (data) => data.dcOpenHoleLength * data.dcOpenHoleCapacity,
      updateMethod: (value) => onDcOpenHoleVolumeChange(value),
      value: null,
    },

    hwdpOpenHoleLength: {
      name: "hwdpOpenHoleLength",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    hwdpOpenHoleCapacity: {
      name: "hwdpOpenHoleCapacity",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    hwdpOpenHoleVolume: {
      name: "hwdpOpenHoleVolume",
      dependencies: ['hwdpOpenHoleLength', 'hwdpOpenHoleCapacity'],
      status: "unknown",
      formula: (data) => data.hwdpOpenHoleLength * data.hwdpOpenHoleCapacity,
      updateMethod: (value) => onHwdpOpenHoleVolumeChange(value),
      value: null,
    },
    openHoleVolume: {
      name: "openHoleVolume",
      dependencies: ['dcOpenHoleVolume', 'hwdpOpenHoleVolume'],
      status: "unknown",
      formula: (data) => data.dcOpenHoleVolume + data.hwdpOpenHoleVolume,
      updateMethod: (value) => onOpenHoleVolumeChange(value),
      value: null,
    },
    openHoleVolumePumpStroke: {
      name: "openHoleVolumePumpStroke",
      dependencies: ["openHoleVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.openHoleVolume / data.pump1Displ,
      updateMethod: (value) => onOpenHoleVolumePumpStrokeChange(value),
      value: null,
    },
    openHoleVolumeTime: {
      name: "openHoleVolumeTime",
      dependencies: ["openHoleVolumePumpStroke", "slowPump1Rate"],
      status: "unknown",
      formula: (data) => data.openHoleVolumePumpStroke / data.slowPump1Rate,
      updateMethod: (value) => onOpenHoleVolumeTimeChange(value),
      value: null,
    },
    dpCasingLength: {
      name: "dpCasingLength",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dpCasingCapacity: {
      name: "dpCasingCapacity",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    dpCasingVolume: {
      name: "dpCasingVolume",
      dependencies: ['dpCasingLength', 'dpCasingCapacity'],
      status: "unknown",
      formula: (data) => data.dpCasingLength * data.dpCasingCapacity,
      updateMethod: (value) => onDpCasingVolumeChange(value),
      value: null,
    },
    dpCasingVolumePumpStroke: {
      name: "dpCasingVolumePumpStroke",
      dependencies: ["dpCasingVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.dpCasingVolume / data.pump1Displ,
      updateMethod: (value) => onDpCasingVolumePumpStrokeChange(value),
      value: null,
    },
    dpCasingVolumeTime: {
      name: "dpCasingVolumeTime",
      dependencies: ["dpCasingVolumePumpStroke", "slowPump1Rate"],
      status: "unknown",
      formula: (data) => data.dpCasingVolumePumpStroke / data.slowPump1Rate,
      updateMethod: (value) => onDpCasingVolumeTimeChange(value),
      value: null,
    },
    totalAnnulusVolume: {
      name: "totalAnnulusVolume",
      dependencies: ['openHoleVolume', 'dpCasingVolume'],
      status: "unknown",
      formula: (data) => data.openHoleVolume + data.dpCasingVolume,
      updateMethod: (value) => onTotalAnnulusVolumeChange(value),
      value: null,
    },
    totalAnnulusVolumePumpStroke: {
      name: "totalAnnulusVolumePumpStroke",
      dependencies: ["totalAnnulusVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.totalAnnulusVolume / data.pump1Displ,
      updateMethod: (value) => onTotalAnnulusVolumePumpStrokeChange(value),
      value: null,
    },
    totalAnnulusVolumeTime: {
      name: "totalAnnulusVolumeTime",
      dependencies: ["totalAnnulusVolumePumpStroke", "slowPump1Rate"],
      status: "unknown",
      formula: (data) => data.totalAnnulusVolumePumpStroke / data.slowPump1Rate,
      updateMethod: (value) => onTotalAnnulusVolumeTimeChange(value),
      value: null,
    },
    totalWellSystemVolume: {
      name: "totalWellSystemVolume",
      dependencies: ['drillStringVolume', 'totalAnnulusVolume'],
      status: "unknown",
      formula: (data) => data.drillStringVolume + data.totalAnnulusVolume,
      updateMethod: (value) => onTotalWellSystemVolumeChange(value),
      value: null,
    },
    totalWellSystemVolumePumpStroke: {
      name: "totalWellSystemVolumePumpStroke",
      dependencies: ["totalWellSystemVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.totalWellSystemVolume / data.pump1Displ,
      updateMethod: (value) => onTotalWellSystemVolumePumpStrokeChange(value),
      value: null,
    },
    totalWellSystemVolumeTime: {
      name: "totalWellSystemVolumeTime",
      dependencies: ["totalWellSystemVolumePumpStroke", "slowPump1Rate"],
      status: "unknown",
      formula: (data) => data.totalWellSystemVolumePumpStroke / data.slowPump1Rate,
      updateMethod: (value) => onTotalWellSystemVolumeTimeChange(value),
      value: null,
    },
    activeSurfaceVolume: {
      name: "activeSurfaceVolume",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    activeSurfaceVolumePumpStroke: {
      name: "activeSurfaceVolumePumpStroke",
      dependencies: ["activeSurfaceVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.activeSurfaceVolume / data.pump1Displ,
      updateMethod: (value) => onActiveSurfaceVolumePumpStrokeChange(value),
      value: null,
    },
    totalActiveFluidVolume: {
      name: "totalActiveFluidVolume",
      dependencies: ['totalWellSystemVolume', 'activeSurfaceVolume'],
      status: "unknown",
      formula: (data) => data.totalWellSystemVolume + data.activeSurfaceVolume,
      updateMethod: (value) => onTotalActiveFluidVolumeChange(value),
      value: null,
    },
    totalActiveFluidVolumePumpStroke: {
      name: "totalActiveFluidVolumePumpStroke",
      dependencies: ["totalActiveFluidVolume", "pump1Displ"],
      status: "unknown",
      formula: (data) => data.totalActiveFluidVolume / data.pump1Displ,
      updateMethod: (value) => onTotalActiveFluidVolumePumpStrokeChange(value),
      value: null,
    },
    sidpp: {
      name: "sidpp",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    sicp: {
      name: "sicp",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    pitGain: {
      name: "pitGain",
      dependencies: [],
      status: "unknown",
      value: null,
    },
    killMudWeight: {
      name: "killMudWeight",
      dependencies: ["mudWeight", "sidpp", "casingTVDepth"],
      status: "unknown",
      // TODO is it casingTVD ?
      formula: (data) => data.mudWeight + (data.sidpp / (data.casingTVDepth * 0.052)),
      updateMethod: (value) => onKillMudWeightChange(value),
      value: null,
    },
    initialCirculatingPressure: {
      name: "initialCirculatingPressure",
      dependencies: ["dynamicPressureLossPump1", "sidpp"],
      status: "unknown",
      formula: (data) => data.dynamicPressureLossPump1 + data.sidpp,
      updateMethod: (value) => onInitialCirculatingPressureChange(value),
      value: null,
    },
    finalCirclePressure: {
      name: "finalCirclePressure",
      dependencies: ["killMudWeight", "mudWeight", "dynamicPressureLossPump1"],
      status: "unknown",
      formula: (data) => (data.killMudWeight / data.mudWeight) * data.dynamicPressureLossPump1,
      updateMethod: (value) => onFinalCirclePressureChange(value),
      value: null,
    },
    circulationPressureDifference: {
      name: "circulationPressureDifference",
      dependencies: ["initialCirculatingPressure", "finalCirclePressure"],
      status: "unknown",
      formula: (data) => data.initialCirculatingPressure - data.finalCirclePressure,
      updateMethod: (value) => onCirculationPressureDifferenceChange(value),
      value: null,
    },
    circulationPressureDifferencePer100Strokes: {
      name: "circulationPressureDifferencePer100Strokes",
      dependencies: ["circulationPressureDifference", "drillStringVolumePumpStroke"],
      status: "unknown",
      formula: (data) => (data.circulationPressureDifference * 100) / data.drillStringVolumePumpStroke,
      updateMethod: (value) => onCirculationPressureDifferencePer100StrokesChange(value),
      value: null,
    },

  };
  const [data, setData] = useState(variables);
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  })
  const [readOnly, setReadOnly] = useState(false);

  const onMudWeightChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, mudWeight: { ...data.mudWeight, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, mudWeight: { ...data.mudWeight, value: parseFloat(e.target.value), status: "given" } });
  };
  const onCasingSizeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, casingSize: { ...data.casingSize, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, casingSize: { ...data.casingSize, value: parseFloat(e.target.value), status: "given" } });
  };
  const onCasingMDepthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, casingMDepth: { ...data.casingMDepth, value: null, status: "unknown" }
      });
    };
    setData({ ...data, casingMDepth: { ...data.casingMDepth, value: parseFloat(e.target.value), status: "given" } });
  };
  const onCasingTVDepthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, casingTVDepth: { ...data.casingTVDepth, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, casingTVDepth: { ...data.casingTVDepth, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHoleSizeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, holeSize: { ...data.holeSize, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, holeSize: { ...data.holeSize, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHoleMDepthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, holeMDepth: { ...data.holeMDepth, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, holeMDepth: { ...data.holeMDepth, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHoleTVDepthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, holeTVDepth: { ...data.holeTVDepth, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, holeTVDepth: { ...data.holeTVDepth, value: parseFloat(e.target.value), status: "given" } });
  };
  const onSurfaceLeakOffPressureChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, surfaceLeakOffPressure: { ...data.surfaceLeakOffPressure, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, surfaceLeakOffPressure: { ...data.surfaceLeakOffPressure, value: parseFloat(e.target.value), status: "given" } });
  };
  const onMudWeightAtTestChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, mudWeightAtTest: { ...data.mudWeightAtTest, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, mudWeightAtTest: { ...data.mudWeightAtTest, value: parseFloat(e.target.value), status: "given" } });
  };
  const onMaximumAllowableMudWeightChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, maximumAllowableMudWeight: { ...data.maximumAllowableMudWeight, value: null, status: "unknown" }
      });
    }
    setData({ ...data, maximumAllowableMudWeight: { ...data.maximumAllowableMudWeight, value: parseFloat(e.target.value), status: "given" } });
  };
  const onInitialMAASPChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, initialMAASP: { ...data.initialMAASP, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, initialMAASP: { ...data.initialMAASP, value: parseFloat(e.target.value), status: "given" } });
  };
  const onPump1DisplChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, pump1Displ: { ...data.pump1Displ, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, pump1Displ: { ...data.pump1Displ, value: parseFloat(e.target.value), status: "given" } });
  };
  const onPump2DisplChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, pump2Displ: { ...data.pump2Displ, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, pump2Displ: { ...data.pump2Displ, value: parseFloat(e.target.value), status: "given" } });
  };
  const onSlowPump1RateChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, slowPump1Rate: { ...data.slowPump1Rate, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, slowPump1Rate: { ...data.slowPump1Rate, value: parseFloat(e.target.value), status: "given" } });
  };
  const onSlowPump2RateChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, slowPump2Rate: { ...data.slowPump2Rate, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, slowPump2Rate: { ...data.slowPump2Rate, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDynamicPressureLossPump1Change = (e) => {
    if (!e.target.value) {
      setData({ ...data, dynamicPressureLossPump1: { ...data.dynamicPressureLossPump1, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dynamicPressureLossPump1: { ...data.dynamicPressureLossPump1, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDynamicPressureLossPump2Change = (e) => {
    if (!e.target.value) {
      setData({ ...data, dynamicPressureLossPump2: { ...data.dynamicPressureLossPump2, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dynamicPressureLossPump2: { ...data.dynamicPressureLossPump2, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpLengthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpLength: { ...data.dpLength, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dpLength: { ...data.dpLength, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpCapacityChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpCapacity: { ...data.dpCapacity, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dpCapacity: { ...data.dpCapacity, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpVolume: { ...data.dpVolume, value: null, status: "unknown" } });
      return
    }
    setData({ ...data, dpVolume: { ...data.dpVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHwdpLengthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, hwdpLength: { ...data.hwdpLength, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, hwdpLength: { ...data.hwdpLength, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHwdpCapacityChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, hwdpCapacity: { ...data.hwdpCapacity, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, hwdpCapacity: { ...data.hwdpCapacity, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHwdpVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, hwdpVolume: { ...data.hwdpVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, hwdpVolume: { ...data.hwdpVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDcLengthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dcLength: { ...data.dcLength, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dcLength: { ...data.dcLength, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDcCapacityChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dcCapacity: { ...data.dcCapacity, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dcCapacity: { ...data.dcCapacity, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDcVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dcVolume: { ...data.dcVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dcVolume: { ...data.dcVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDrillStringVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, drillStringVolume: { ...data.drillStringVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, drillStringVolume: { ...data.drillStringVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDrillStringVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, drillStringVolumePumpStroke: { ...data.drillStringVolumePumpStroke, value: null, status: "unknown" }
      });
      return;
    }
    setData({ ...data, drillStringVolumePumpStroke: { ...data.drillStringVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDrillStringVolumeTimeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, drillStringVolumeTime: { ...data.drillStringVolumeTime, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, drillStringVolumeTime: { ...data.drillStringVolumeTime, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDcOpenHoleLengthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dcOpenHoleLength: { ...data.dcOpenHoleLength, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dcOpenHoleLength: { ...data.dcOpenHoleLength, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDcOpenHoleCapacityChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dcOpenHoleCapacity: { ...data.dcOpenHoleCapacity, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dcOpenHoleCapacity: { ...data.dcOpenHoleCapacity, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDcOpenHoleVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dcOpenHoleVolume: { ...data.dcOpenHoleVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dcOpenHoleVolume: { ...data.dcOpenHoleVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHwdpOpenHoleLengthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, hwdpOpenHoleLength: { ...data.hwdpOpenHoleLength, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, hwdpOpenHoleLength: { ...data.hwdpOpenHoleLength, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHwdpOpenHoleCapacityChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, hwdpOpenHoleCapacity: { ...data.hwdpOpenHoleCapacity, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, hwdpOpenHoleCapacity: { ...data.hwdpOpenHoleCapacity, value: parseFloat(e.target.value), status: "given" } });
  };
  const onHwdpOpenHoleVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, hwdpOpenHoleVolume: { ...data.hwdpOpenHoleVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, hwdpOpenHoleVolume: { ...data.hwdpOpenHoleVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onOpenHoleVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, openHoleVolume: { ...data.openHoleVolume, value: null, status: "unknown" }
      });
      return;
    }
    setData({ ...data, openHoleVolume: { ...data.openHoleVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onOpenHoleVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, openHoleVolumePumpStroke: { ...data.openHoleVolumePumpStroke, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, openHoleVolumePumpStroke: { ...data.openHoleVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onOpenHoleVolumeTimeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, openHoleVolumeTime: { ...data.openHoleVolumeTime, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, openHoleVolumeTime: { ...data.openHoleVolumeTime, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpCasingLengthChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpCasingLength: { ...data.dpCasingLength, value: null, status: "unknown" }
      });
      return;
    }
    setData({ ...data, dpCasingLength: { ...data.dpCasingLength, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpCasingCapacityChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpCasingCapacity: { ...data.dpCasingCapacity, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dpCasingCapacity: { ...data.dpCasingCapacity, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpCasingVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpCasingVolume: { ...data.dpCasingVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dpCasingVolume: { ...data.dpCasingVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpCasingVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpCasingVolumePumpStroke: { ...data.dpCasingVolumePumpStroke, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dpCasingVolumePumpStroke: { ...data.dpCasingVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onDpCasingVolumeTimeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, dpCasingVolumeTime: { ...data.dpCasingVolumeTime, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, dpCasingVolumeTime: { ...data.dpCasingVolumeTime, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalAnnulusVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalAnnulusVolume: { ...data.totalAnnulusVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalAnnulusVolume: { ...data.totalAnnulusVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalAnnulusVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalAnnulusVolumePumpStroke: { ...data.totalAnnulusVolumePumpStroke, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalAnnulusVolumePumpStroke: { ...data.totalAnnulusVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalAnnulusVolumeTimeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalAnnulusVolumeTime: { ...data.totalAnnulusVolumeTime, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalAnnulusVolumeTime: { ...data.totalAnnulusVolumeTime, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalWellSystemVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalWellSystemVolume: { ...data.totalWellSystemVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalWellSystemVolume: { ...data.totalWellSystemVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalWellSystemVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalWellSystemVolumePumpStroke: { ...data.totalWellSystemVolumePumpStroke, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalWellSystemVolumePumpStroke: { ...data.totalWellSystemVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalWellSystemVolumeTimeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalWellSystemVolumeTime: { ...data.totalWellSystemVolumeTime, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalWellSystemVolumeTime: { ...data.totalWellSystemVolumeTime, value: parseFloat(e.target.value), status: "given" } });
  };
  const onActiveSurfaceVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, activeSurfaceVolume: { ...data.activeSurfaceVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, activeSurfaceVolume: { ...data.activeSurfaceVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onActiveSurfaceVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, activeSurfaceVolumePumpStroke: { ...data.activeSurfaceVolumePumpStroke, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, activeSurfaceVolumePumpStroke: { ...data.activeSurfaceVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalActiveFluidVolumeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalActiveFluidVolume: { ...data.totalActiveFluidVolume, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalActiveFluidVolume: { ...data.totalActiveFluidVolume, value: parseFloat(e.target.value), status: "given" } });
  };
  const onTotalActiveFluidVolumePumpStrokeChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, totalActiveFluidVolumePumpStroke: { ...data.totalActiveFluidVolumePumpStroke, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, totalActiveFluidVolumePumpStroke: { ...data.totalActiveFluidVolumePumpStroke, value: parseFloat(e.target.value), status: "given" } });
  };
  const onSidppChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, sidpp: { ...data.sidpp, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, sidpp: { ...data.sidpp, value: parseFloat(e.target.value), status: "given" } });
  };
  const onSicpChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, sicp: { ...data.sicp, value: null, status: "unknown" }
      });
      return;
    }
    setData({ ...data, sicp: { ...data.sicp, value: parseFloat(e.target.value), status: "given" } });
  };
  const onPitGainChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, pitGain: { ...data.pitGain, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, pitGain: { ...data.pitGain, value: parseFloat(e.target.value), status: "given" } });
  };
  const onKillMudWeightChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, killMudWeight: { ...data.killMudWeight, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, killMudWeight: { ...data.killMudWeight, value: parseFloat(e.target.value), status: "given" } });
  };
  const onInitialCirculatingPressureChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, initialCirculatingPressure: { ...data.initialCirculatingPressure, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, initialCirculatingPressure: { ...data.initialCirculatingPressure, value: parseFloat(e.target.value), status: "given" } });
  };
  const onFinalCirclePressureChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, finalCirclePressure: { ...data.finalCirclePressure, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, finalCirclePressure: { ...data.finalCirclePressure, value: parseFloat(e.target.value), status: "given" } });
  };
  const onCirculationPressureDifferenceChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, circulationPressureDifference: { ...data.circulationPressureDifference, value: null, status: "unknown" } });
      return;
    }
    setData({ ...data, circulationPressureDifference: { ...data.circulationPressureDifference, value: parseFloat(e.target.value), status: "given" } });
  };
  const onCirculationPressureDifferencePer100StrokesChange = (e) => {
    if (!e.target.value) {
      setData({ ...data, circulationPressureDifferencePer100Strokes: { ...data.circulationPressureDifferencePer100Strokes, value: null, status: "unknown" } });
      return;
    }
    setData({
      ...data, circulationPressureDifferencePer100Strokes: {
        ...data.circulationPressureDifferencePer100Strokes, value: parseFloat(e.target.value), status: "given"
      }
    });
  }


  function calculateUnknowns() {
    let updatedVariables = { ...data };
    let canCalculateMore = true;

    // Iterate over variables until no more can be calculated
    while (canCalculateMore) {
      canCalculateMore = false;

      for (let key in updatedVariables) {
        const variable = updatedVariables[key];

        // If the variable is unknown, check if we can calculate it
        if (variable.status === "unknown" && variable.formula && variable.dependencies) {
          const canCalculate = variable.dependencies.every(
            (dep) => updatedVariables[dep].status === "calculated" || updatedVariables[dep].status === "given"
          );

          if (canCalculate) {
            // Calculate the value using its formula
            const values = variable.dependencies.reduce((acc, dep) => {
              acc[dep] = updatedVariables[dep].value;
              return acc;
            }, {});
            
            // round to two places only
            updatedVariables[key].value = Math.round(variable.formula(values) * 100) / 100;
            console.log(variable.name, variable.dependencies, values, variable.formula(values));
            updatedVariables[key].status = "calculated";
            canCalculateMore = true; // Keep looping to calculate other variables
          }
        }
      }
    }

    setData(updatedVariables);
    let initialCirculatingPressure = updatedVariables.initialCirculatingPressure.value;
    const circulationPressureDifferencePer100Strokes = updatedVariables.circulationPressureDifferencePer100Strokes.value;

    let chartData = {
      categories: [],
      series: []
    }

    let i = 0;
    while (initialCirculatingPressure > 0) {
      chartData.categories.push(initialCirculatingPressure);
      chartData.series.push(i * 100);
      initialCirculatingPressure -= circulationPressureDifferencePer100Strokes;
      i++;
    }
    setChartData(chartData);
  }


  const calculate = () => {
    calculateUnknowns();
  }
  return (

    <div className=" mt-10 mb-10 mx-12 ">
      <div className="flex gap-20 mb-10">

      <button className="btn  " onClick={calculate}>Calculate</button>
      

      <div className="form-control">
        <label className="label cursor-pointer gap-2">
          <span className="label-text">Read Only</span>
          <input type="checkbox" className="toggle" checked={readOnly} onClick={() => setReadOnly(!readOnly)} />
        </label>
      </div>
      </div>

      {/* <button className="btn  mb-20 mx-auto" onClick={() => setReadOnly(!readOnly)}>ReadOnly</button> */}


      <div className="collapse collapse-arrow mb-10 border">
        <input type="checkbox" name="my-accordion-2" />
        <div className="collapse-title font-bold text-xl">WELL & FORMATION STRENGTH DATA</div>
        <div className="collapse-content">

          <div className="flex justify-between ">

            {/* FORMATION STRENGTH DATA  */}
            <div className="mt-10">
              <h1 className="font-bold">FORMATION STRENGTH DATA</h1>

              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  SURFACE LEAK-OFF PRESSURE FROM FORMATION STRENGTH TEST
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.surfaceLeakOffPressure}
                  onChange={onSurfaceLeakOffPressureChange}
                  unit="psi"
                  label="(A)"
                />
              </div>

              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  MUD WEIGHT AT TEST
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.mudWeightAtTest}
                  onChange={onMudWeightAtTestChange}
                  unit="ppg"
                  label="(B)"
                />

              </div>

              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  MAXIMUM ALLOWABLE MUD WEIGHT <br />
                  <span className="text-xs"> (B) + (A) / SHOE T.V.DEPTH * 0.052 </span>
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.maximumAllowableMudWeight}
                  onChange={onMaximumAllowableMudWeightChange}
                  unit="ppg"
                  label="(C)"
                />
              </div>

              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  INITIAL MAASP <br /> <span className="text-xs">( (C) - CURRENT MUD WEIGHT ) * SHOE T.V.DEPTH * 0.052  </span>
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.initialMAASP}
                  onChange={onInitialMAASPChange}
                  unit="psi"
                  label={null}
                />
              </div>

              {/* PUMP DISPL  */}
              <div className="mt-10">
                <div className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>PUMP NO. 1 DISPL</th>
                        <th>PUMP NO. 2 DISPL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <td>
                          <DynamicInput
                            readOnly={readOnly}
                            data={data.pump1Displ}
                            onChange={onPump1DisplChange}
                            unit="bbls / stroke"
                            label={null}
                          />
                        </td>
                        <td>
                          <DynamicInput
                            readOnly={readOnly}
                            data={data.pump2Displ}
                            onChange={onPump2DisplChange}
                            unit="bbls / stroke"
                            label={null}
                          />
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

              {/* Dynamic Pressure loss  */}
              <div className="mt-10">
                <div className="overflow-x-scroll">
                  <table className="table">
                    {/* head */}
                    <thead>
                      <tr>
                        <th className="bg-base-200 rounded-lg"></th>
                        <th className="text-center" colSpan="2">(PL) DYNAMIC PRESSURE LOSS [psi]</th>

                      </tr>
                    </thead>
                    <tbody>
                      {/* row 1 */}
                      <tr>
                        <td>SLOW PUMP RATE DATA:</td>
                        <td>PUMP NO. 1</td>
                        <td>PUMP NO. 2</td>


                      </tr>
                      {/* row 2 */}
                      <tr>
                        <td>
                          <DynamicInput
                            readOnly={readOnly}
                            data={data.slowPump1Rate}
                            onChange={onSlowPump1RateChange}
                            unit="SPM"
                            label={null}
                          />
                        </td>
                        <td>
                          <DynamicInput
                            readOnly={readOnly}
                            data={data.dynamicPressureLossPump1}
                            onChange={onDynamicPressureLossPump1Change}
                            unit="PSI"
                            label={null}
                          />
                        </td>
                        <td>
                          <DynamicInput
                            readOnly={readOnly}
                            data={data.dynamicPressureLossPump2}
                            onChange={onDynamicPressureLossPump2Change}
                            unit="PSI"
                            label={null}
                          />
                        </td>

                      </tr>

                      {/* TODO do we need slow rate2 and pump 1, 2 for this slow rate */}
                      {/* <tr>
            <td><label className="input input-bordered flex items-center gap-2 w-44">
              <input type="number" min="0" className="w-24" />
              <span className="badge badge-info">SPM</span>
            </label></td>
            <td><label className="input input-bordered flex items-center gap-2 w-44">
              <input type="number" min="0" className="w-24" />
              <span className="badge badge-info">PSI</span>
            </label></td>
            <td><label className="input input-bordered flex items-center gap-2 w-44">
              <input type="number" min="0" className="w-24" />
              <span className="badge badge-info">PSI</span>
            </label></td>

          </tr> */}
                    </tbody>
                  </table>
                </div>

              </div>

            </div>

            {/* CURRENT WELL DATA  */}
            <div className=" flex gap-3">
              <div>
                <h1 className="font-bold">CURRENT WELL DATA:</h1>

                <div className="mt-14">

                  <h1 className="font-bold">CURRENT DRILLING MUD</h1>
                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      WEIGHT
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.mudWeight}
                      onChange={onMudWeightChange}
                      unit="ppg"
                      label={null}
                    />

                  </div>
                </div>

                <div className="mt-14">
                  <h1 className="font-bold">CURRENT SHOE DATA:</h1>
                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      SIZE
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.casingSize}
                      onChange={onCasingSizeChange}
                      unit="inch"
                      label={null}
                    />

                  </div>

                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      M. DEPTH
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.casingMDepth}
                      onChange={onCasingMDepthChange}
                      unit="feet"
                      label={null}
                    />

                  </div>

                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      T.V. DEPTH
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.casingTVDepth}
                      onChange={onCasingTVDepthChange}
                      unit="feet"
                      label={null}
                    />

                  </div>
                </div>

                <div className="mt-14">
                  <h1 className="font-bold">HOLE DATA:</h1>
                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      SIZE
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.holeSize}
                      onChange={onHoleSizeChange}
                      unit="inch"
                      label={null}
                    />

                  </div>

                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      M. DEPTH
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.holeMDepth}
                      onChange={onHoleMDepthChange}
                      unit="feet"
                      label={null}
                    />

                  </div>

                  <div className="flex items-center gap-2  entry mt-2">
                    <p className="w-40">

                      T.V. DEPTH
                    </p>
                    <DynamicInput
                      readOnly={readOnly}
                      data={data.holeTVDepth}
                      onChange={onHoleTVDepthChange}
                      unit="feet"
                      label={null}
                    />

                  </div>
                </div>

              </div>
              <div><img src={DrillImage} alt="fireSpot" /></div>
            </div>
          </div>


        </div>
      </div>


      <div className="collapse collapse-arrow mb-10 border">
        <input type="checkbox" name="my-accordion-2" />
        <div className="collapse-title font-bold text-xl">PRE-RECORDED VOLUME DATA</div>
        <div className="collapse-content">
          <div className="mt-10 ">
            <div className="overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>PRE-RECORDED VOLUME DATA</th>
                    <th>LENGTH <span className="badge badge-info text-xs">feet</span></th>
                    <th>CAPACITY <span className="badge badge-info text-xs">bbls / foot</span></th>
                    <th>VOLUME <span className="badge badge-info text-xs">bbls</span></th>
                    <th>PUMP STROKES <span className="badge badge-info text-xs">strokes</span></th>
                    <th>TIME <span className="badge badge-info text-xs">Min</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td>DRILL PIPE</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpLength}
                        onChange={onDpLengthChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpCapacity}
                        onChange={onDpCapacityChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpVolume}
                        onChange={onDpVolumeChange}
                        unit={null}
                        label={null}
                      />

                    </td>
                    <td rowSpan="3">VOLUME / PUMP DISPLACEMENT</td>
                    <td rowSpan="3">PUMP STROKES / SLOW PUMP RATE</td>
                  </tr>

                  <tr >
                    <td>HEAVY WALL DRILL PIPE</td>
                    <td>

                      <DynamicInput
                        readOnly={readOnly}
                        data={data.hwdpLength}
                        onChange={onHwdpLengthChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>

                      <DynamicInput
                        readOnly={readOnly}
                        data={data.hwdpCapacity}
                        onChange={onHwdpCapacityChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.hwdpVolume}
                        onChange={onHwdpVolumeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                  </tr>

                  <tr >
                    <td>DRILL COLLARS</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dcLength}
                        onChange={onDcLengthChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dcCapacity}
                        onChange={onDcCapacityChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>

                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dcVolume}
                        onChange={onDcVolumeChange}
                        unit={null}
                        label={null}
                      />
                    </td>

                  </tr>

                  <tr >
                    <td colSpan="3" className="font-bold">DRILL STRING VOLUME</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.drillStringVolume}
                        onChange={onDrillStringVolumeChange}
                        unit={null}
                        label="(D)"
                      />

                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.drillStringVolumePumpStroke}
                        onChange={onDrillStringVolumePumpStrokeChange}
                        unit={null}
                        label="(E)"
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.drillStringVolumeTime}
                        onChange={onDrillStringVolumeTimeChange}
                        unit={null}
                        label={null}
                      />
                    </td>

                  </tr>

                  <tr >
                    <td>DC * OPEN HOLE</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dcOpenHoleLength}
                        onChange={onDcOpenHoleLengthChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dcOpenHoleCapacity}
                        onChange={onDcOpenHoleCapacityChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dcOpenHoleVolume}
                        onChange={onDcOpenHoleVolumeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td className="bg-base-200 "></td>
                    <td className="bg-base-200" ></td>
                  </tr>


                  <tr >
                    <td>DP / HWDP * OPEN HOLE</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.hwdpOpenHoleLength}
                        onChange={onHwdpOpenHoleLengthChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.hwdpOpenHoleCapacity}
                        onChange={onHwdpOpenHoleCapacityChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.hwdpOpenHoleVolume}
                        onChange={onHwdpOpenHoleVolumeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td className="bg-base-200"></td>
                    <td className="bg-base-200" ></td>
                  </tr>

                  <tr >
                    <td className="font-bold" colSpan="3">OPEN HOLE VOLUME</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.openHoleVolume}
                        onChange={onOpenHoleVolumeChange}
                        unit={null}
                        label="(F)"
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.openHoleVolumePumpStroke}
                        onChange={onOpenHoleVolumePumpStrokeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.openHoleVolumeTime}
                        onChange={onOpenHoleVolumeTimeChange}
                        unit={null}
                        label={null}
                      />

                    </td>

                  </tr>

                  <tr >
                    <td >DP * CASING</td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpCasingLength}
                        onChange={onDpCasingLengthChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td><DynamicInput
                      readOnly={readOnly}
                      data={data.dpCasingCapacity}
                      onChange={onDpCasingCapacityChange}
                      unit={null}
                      label={null}
                    />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpCasingVolume}
                        onChange={onDpCasingVolumeChange}
                        unit={null}
                        label="(G)"
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpCasingVolumePumpStroke}
                        onChange={onDpCasingVolumePumpStrokeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.dpCasingVolumeTime}
                        onChange={onDpCasingVolumeTimeChange}
                        unit={null}
                        label={null}
                      />
                    </td>

                  </tr>


                  <tr >
                    <td colSpan="1" className="font-bold">TOTAL ANNULUS VOLUME</td>
                    <td colSpan="3">
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalAnnulusVolume}
                        onChange={onTotalAnnulusVolumeChange}
                        unit="bbls"
                        label="(F+G) = (H)"
                      />

                    </td>


                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalAnnulusVolumePumpStroke}
                        onChange={onTotalAnnulusVolumePumpStrokeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalAnnulusVolumeTime}
                        onChange={onTotalAnnulusVolumeTimeChange}
                        unit={null}
                        label={null}
                      />
                    </td>

                  </tr>

                  <tr >
                    <td colSpan="1" className="font-bold">TOTAL WELL SYSTEM VOLUME</td>
                    <td colSpan="3">
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalWellSystemVolume}
                        onChange={onTotalWellSystemVolumeChange}
                        unit="bbls"
                        label="(D+H) = (I)"
                      />
                    </td>


                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalWellSystemVolumePumpStroke}
                        onChange={onTotalWellSystemVolumePumpStrokeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalWellSystemVolumeTime}
                        onChange={onTotalWellSystemVolumeTimeChange}
                        unit={null}
                        label={null}
                      />
                    </td>

                  </tr>

                  <tr >
                    <td colSpan="">ACTIVE SURFACE VOLUME</td>
                    <td colSpan="3">
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.activeSurfaceVolume}
                        onChange={onActiveSurfaceVolumeChange}
                        unit="bbls"
                        label="(J)"
                      />
                    </td>


                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.activeSurfaceVolumePumpStroke}
                        onChange={onActiveSurfaceVolumePumpStrokeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td className="bg-base-200"></td>

                  </tr>

                  <tr >
                    <td colSpan="1" className="font-bold">TOTAL ACTIVE FLUID SYSTEM</td>
                    <td colSpan="3">
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalActiveFluidVolume}
                        onChange={onTotalActiveFluidVolumeChange}
                        unit="bbls"
                        label="(I+J)"
                      />
                    </td>


                    <td>
                      <DynamicInput
                        readOnly={readOnly}
                        data={data.totalActiveFluidVolumePumpStroke}
                        onChange={onTotalActiveFluidVolumePumpStrokeChange}
                        unit={null}
                        label={null}
                      />
                    </td>
                    <td className="bg-base-200"></td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div className="collapse collapse-arrow mb-10 border">
        <input type="checkbox" name="my-accordion-2" />
        <div className="collapse-title text-xl font-bold">KICK DATA</div>
        <div className="collapse-content">
          <div className="mt-10">


            <div className="flex justify-around">
              <div className="flex items-center gap-2  entry mt-5">
                <p className="">

                  SIDPP
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.sidpp}
                  onChange={onSidppChange}
                  unit="psi"
                  label={null}
                />

              </div>
              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-">

                  SICP
                </p>

                <DynamicInput
                  readOnly={readOnly}
                  data={data.sicp}
                  onChange={onSicpChange}
                  unit="psi"
                  label={null}
                />

              </div>
              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-">

                  PIT GAIN
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.pitGain}
                  onChange={onPitGainChange}
                  unit="bbls"
                  label={null}
                />

              </div>
            </div>



            <div className="mt-10">


              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  KILL MUD WEIGHT <br />
                  <span className="text-xs"> CURRENT MUD WEIGHT + SIDPP / TVD * 0.052 </span>
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.killMudWeight}
                  onChange={onKillMudWeightChange}
                  unit="ppg"
                  label={null}
                />
              </div>


              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  INITIAL CIRCULATING PRESSURE <br />
                  <span className="text-xs"> DYNAMIC PRESSURE LOSS + SIDPP </span>
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.initialCirculatingPressure}
                  onChange={onInitialCirculatingPressureChange}
                  unit="psi"
                  label={null}
                />
              </div>

              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-96">

                  FINAL CIRCULATING PRESSURE <br />
                  <span className="text-xs">KILL MUD WEIGHT / CURRENT MUD WEIGHT  * DYNAMIC PRESSURE LOSS </span>
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.finalCirclePressure}
                  onChange={onFinalCirclePressureChange}
                  unit="psi"
                  label={null}
                />
              </div>

            </div>





            <div className="mt-10 flex justify-around ">
              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-">

                  ICP - FCP
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.circulationPressureDifference}
                  onChange={onCirculationPressureDifferenceChange}
                  unit="psi"
                  label="(K)"
                />

              </div>
              <div className="flex items-center gap-2  entry mt-5">
                <p className="w-">

                  (K) * 100 / (E)
                </p>
                <DynamicInput
                  readOnly={readOnly}
                  data={data.circulationPressureDifferencePer100Strokes}
                  onChange={onCirculationPressureDifferencePer100StrokesChange}
                  unit="psi / 100 strokes"
                  label={null}
                />

              </div>
            </div>

            {/* chart js */}
            <div className="mt-10">
              <LineChart series={chartData.series} categories={chartData.categories} />

            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
