const DynamicInput = ({ readOnly,label,data, onChange, unit }) => {
    
    let labelWidth = unit ? 48 : 28;
    let inputWidth = unit ? 24 : 20;

    return (

      <label
        className={`input   flex items-center gap-2 w-${labelWidth} ${
          data.status === 'given' ? 'border-blue-500' : 'border-gray-300'
        }`}
      >
        {label}
        <input
          type="number"
          value={data.value}
          onChange={onChange}
          className={`w-${inputWidth}  input-bordered`}
          min="0"
          disabled={readOnly}
        />
        {unit && <span className="badge badge-info">{unit}</span>}
      </label>
    );
  };
  
  export default DynamicInput;